import translate from "@/utils/translation"
import { Avatar } from "@mui/material"
import { Link } from "next-translate-routes"
import React, { useState } from "react"
import { ChevronRight, Instagram, Linkedin } from "react-bootstrap-icons"
import styled from "styled-components"

const JumboAbout = ({ isVisible, setIsJumboAboutActive }) => {
  const [activeItem, setActiveItem] = useState(1)

  return (
    <Wrapper
      isVisible={isVisible}
      onMouseEnter={() => setIsJumboAboutActive(true)}
      onMouseLeave={() => setIsJumboAboutActive(false)}
    >
      <div className="inner container">
        <div className="row pt-3 pb-4">
          <div className="col-4 menu">
            <ul className="menu-items">
              <Link
                href="/about-us"
                className={`menu-item d-flex justify-content-between ${
                  activeItem === 1 ? "active" : ""
                }`}
                onMouseEnter={() => setActiveItem(1)}
              >
                {translate("menu_jumbo_about_us")}
                <ChevronRight className="icon" />
              </Link>
              <Link
                href="/securities"
                className={`menu-item d-flex justify-content-between ${
                  activeItem === 2 ? "active" : ""
                }`}
                onMouseEnter={() => setActiveItem(2)}
              >
                {translate("menu_jumbo_securities")}
                <ChevronRight className="icon" />
              </Link>
              <Link
                href="/showcase"
                className={`menu-item d-flex justify-content-between ${
                  activeItem === 3 ? "active" : ""
                }`}
                onMouseEnter={() => setActiveItem(3)}
              >
                {translate("menu_jumbo_trackrecord")}
                <ChevronRight className="icon" />
              </Link>
              {/* <div className="social">
                <div className="mt-3 d-flex">
                  <Avatar
                    src="/pages/about/employees/Jordan.jpg"
                    sx={{ height: 45, width: 45 }}
                  />
                  <div className="ms-3 mt-0">
                    <h5>{translate("menu_jumbo_follow_us")}</h5>
                    <p>{translate("menu_jumbo_follow_us_description")}</p>
                  </div>
                </div>
              </div> */}
            </ul>
          </div>
          <div className="col-8">
            <div className="resources">
              {activeItem === 1 && (
                <>
                  <p>{translate("menu_jumbo_about_description")}</p>
                  <p className="small mt-4">
                    {translate("menu_jumbo_resources")}
                  </p>
                  <ul className="resource-items">
                    <li>
                      <Link href="/about-us#mission">
                        {translate("menu_jumbo_about_mission")}
                      </Link>
                    </li>
                    <li>
                      <Link href="/about-us#team">
                        {translate("menu_jumbo_about_team")}
                      </Link>
                    </li>
                    <li>
                      <Link href="/blog/valvest-investment-principles">
                        {translate("menu_jumbo_about_principles")}
                      </Link>
                    </li>
                  </ul>
                </>
              )}

              {activeItem === 2 && (
                <>
                  <p>{translate("menu_jumbo_securities_description")}</p>
                  <p className="small mt-4">
                    {translate("menu_jumbo_resources")}
                  </p>
                  <ul className="resource-items">
                    <li>
                      <Link href="/helpcenter/article/777-what-are-the-risks-when-investing-with-valvest">
                        {translate("menu_jumbo_securities_risks")}
                      </Link>
                    </li>
                    <li>
                      <Link href="/helpcenter/article/266-do-i-become-an-owner-of-the-property-when-i-invest">
                        {translate("menu_jumbo_securities_owner")}
                      </Link>
                    </li>
                    <li>
                      <Link href="/helpcenter/article/107-what-happens-if-a-tenant-does-not-pay-rent">
                        {translate("menu_jumbo_securities_tenant")}
                      </Link>
                    </li>
                  </ul>
                </>
              )}
              {activeItem === 3 && (
                <>
                  <p>{translate("menu_jumbo_trackrecord_description")}</p>
                  <p className="small mt-4">
                    {translate("menu_jumbo_resources")}
                  </p>
                  <ul className="resource-items">
                    <li>
                      <Link href="/helpcenter/article/75-who-handles-the-renovations">
                        {translate(
                          "menu_jumbo_trackrecord_who_handles_renovations"
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link href="/helpcenter/article/265-where-does-valvest-buys-its-properties">
                        {translate("menu_jumbo_trackrecord_where_valvest_buys")}
                      </Link>
                    </li>
                    <li>
                      <Link href="/helpcenter/article/264-how-did-valvest-start">
                        {translate(
                          "menu_jumbo_trackrecord_how_did_valvest_start"
                        )}
                      </Link>
                    </li>
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default JumboAbout

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 25px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props =>
    props.isVisible ? "translateY(0)" : "translateY(0px)"};
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: ${props => (props.isVisible ? "auto" : "none")};
  z-index: 90000;

  cursor: default;

  .inner {
    position: relative;
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 9999999;
  }

  .row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .menu {
    background: white;
    padding: 0rem 1.5rem 0 2.5rem;
    flex: 1;

    .menu-items {
      list-style: none;
      padding: 0;
      margin: 0;

      .menu-item {
        padding: 0.5rem 0;
        cursor: pointer;
        border-bottom: 1px solid #eee;
        line-height: 2;
        font-weight: 500;
        font-size: 0.95rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;

        &:last-child {
          border: none;
        }

        .icon {
          margin-top: 0.2rem;
        }

        &.active {
          color: orange;

          font-weight: 500;
        }
      }
    }
  }

  .resources {
    background: #f9f9f9;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 1rem 1rem;
    flex: 2;

    p {
      margin: 0 0 0.5rem 0;
      font-size: 0.85rem;
    }

    .resource-items {
      list-style: none;
      padding: 0;
      margin: 0;

      li,
      a {
        padding: 0.4rem 0;
        cursor: pointer;
        font-size: 0.75rem;
        color: rgb(21, 108, 216);
        text-decoration: underline;
        font-weight: 300;
      }
    }

    .small {
      font-size: 0.75rem;
      font-weight: 600;
      color: #333;
    }
  }

  .social {
    padding: 0rem 1rem 1rem;
    display: flex;
    flex-direction: column;

    border-radius: 10px;

    background: #fceed0;
    margin-top: 1rem;
    margin-bottom: 1rem;

    p {
      font-size: 0.85rem;
      margin: 0;
      padding: 0;
    }

    h5 {
      padding: 0;
      margin: 0;
    }

    a {
      svg {
        width: 1.5rem;

        height: 1.5rem;

        color: #333;
      }
    }
  }

  @media (min-width: 768px) {
    .row {
      flex-direction: row;
    }
  }
`
