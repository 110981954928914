import { useEffect, useReducer, createContext } from "react"
import { AppReducer } from "./reducer"

// Initial state
const initialState = {
  listing: {},
  listingView: false,
  showMenu: true,
  showedDiscount: false,
  id: Math.floor(Math.random() * 1000000000),
}

// Create context
const Context = createContext({})

// Context provider
const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState)
  const value = { state, dispatch }

  useEffect(() => {
    try {
      const storedState = JSON.parse(localStorage.getItem("state"))

      if (storedState) {
        // Ensure an id is set for stored state
        if (!storedState.id) {
          storedState.id = Math.floor(Math.random() * 1000000000)
          storedState.showMenu = true
        }
        dispatch({
          type: "init_stored",
          payload: storedState,
        })
      } else {
        // Initialize with a generated id in initial state if no stored state exists
        const newState = {
          ...initialState,
          id: Math.floor(Math.random() * 1000000000),
          showMenu: true,
        }
        dispatch({
          type: "init",
          payload: newState,
        })
      }
    } catch (error) {
      // Fallback to initial state with generated id
      const newState = {
        ...initialState,
        id: Math.floor(Math.random() * 1000000000),
      }
      dispatch({
        type: "init",
        payload: newState,
      })
    }
  }, [])

  useEffect(() => {
    // Only update localStorage when state changes
    localStorage.setItem("state", JSON.stringify(state))
  }, [state])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { Context, ContextProvider }
