// OnboardingSteps.jsx
import React, { useContext, useState } from "react"
import styled from "styled-components"
import { Button } from "@mui/material"
import { useSession } from "next-auth/react"
import { Context } from "@/context/state"
import moment from "moment"
import translate from "@/utils/translation"

const DiscountModal = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const { data: session } = useSession()
  const { state, dispatch } = useContext(Context)

  const title = translate("bonus_activated_title")
  const description = translate("bonus_activated_description")

  const steps = [
    {
      title: title,
      description: description,
      image: "🎊",
    },
  ]

  const handleClose = async () => {
    dispatch({ type: "SET_DISCOUNT" })
  }

  const showModal =
    session?.discount?.amount &&
    !state?.showedDiscount &&
    moment(session.createdAt).isSame(moment(), "day")
      ? true
      : false

  if (!showModal) return null

  return (
    <Wrapper>
      <Modal role="dialog" aria-modal="true" aria-labelledby="onboarding-title">
        <CloseButton
          onClick={() => handleClose()}
          aria-label="Close onboarding"
        >
          &times;
        </CloseButton>
        <StepContent>
          <StepHeader>{steps[0].image}</StepHeader>
          <Title id="onboarding-title">
            {"€" +
              session?.discount?.amount +
              " " +
              steps[currentStep - 1].title}
          </Title>
          <Description>{steps[currentStep - 1].description}</Description>
        </StepContent>
        <ButtonContainer>
          <Button
            variant="contained"
            className="rounded-pill w-50 p-2"
            onClick={() => handleClose()}
          >
            Starten
          </Button>
        </ButtonContainer>
      </Modal>
    </Wrapper>
  )
}

export default DiscountModal
// Styled Components (can be placed within the same file or imported)
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`

const Modal = styled.div`
  background-color: #fff;
  width: 90%;
  max-width: 600px;
  padding: 40px 30px;
  border-radius: 5px;
  position: relative;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 24px;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s ease;

  &:hover {
    color: #333;
  }
`

const StepContent = styled.div`
  margin-bottom: 30px;
`

const Title = styled.h2`
  margin-bottom: 15px;
  font-size: 26px;
  color: #333;
`

const Description = styled.p`
  margin-bottom: 25px;
  font-size: 1rem;
  color: #555;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.single ? "center" : "space-between")};
  gap: 10px;
  justify-content: center;
`

const StepHeader = styled.div`
  font-size: 6rem;
  margin-bottom: 1rem;
`
